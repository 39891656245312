






















































































































import Vue from "vue";
import { State } from "@/store";

import ActionButton from "@/components/ActionButton.vue";
import MetadataBlurb from "@/components/MetadataBlurb.vue";
import UserName from "@/components/UserName.vue";

import {
  USER_PERMISSION_NORMAL,
  USER_PERMISSION_ADMIN,
  USER_PERMISSION_SUPER
} from "@/permissions";

export default Vue.extend({
  name: "MessageView",
  components: {
    ActionButton,
    MetadataBlurb,
    UserName
  },
  props: {
    messageId: { type: String, required: true }
  },
  data: () => ({
    error: null as Error | string | null,
    isWorking: false,
    cachedMessage: null as Messages.UserFeedback | null // used while deleting so we retain visuals
  }),
  computed: {
    state(): State {
      return this.$store.direct.state;
    },
    message(): Messages.UserFeedback | null {
      return this.cachedMessage ?? this.state.messages.all[this.messageId] ?? null;
    },
    sender(): Account.User | null {
      if (this.message?.sentBy) {
        return this.state.users.items[this.message.sentBy] ?? null;
      }
      return null;
    },
    senderAccount(): Account.Info | null {
      if (this.message?.currentAccount) {
        return this.state.accounts.all[this.message.currentAccount] ?? null;
      }
      return null;
    },
    senderName(): string | null {
      const sender = this.sender;
      if (sender) {
        return `${sender.firstName?.trim() ?? ""} ${sender.lastName?.trim() ?? ""}`.trim() || null;
      }
      return null;
    },
    senderPhone(): string | null {
      return this.sender?.phone?.replace(/\D/g, "") ?? null;
    },
    senderPermissionsRole(): string | null {
      const level = this.message?.currentPermissions?.level ?? null;
      if (level === USER_PERMISSION_NORMAL) {
        return "Referrer";
      } else if (level === USER_PERMISSION_ADMIN) {
        return "Admin";
      } else if (level === USER_PERMISSION_SUPER) {
        return "Superuser";
      }
      return level;
    },
    accountPhone(): string | null {
      return this.senderAccount?.phoneNumber?.replace(/\D/g, "") ?? null;
    },
    senderDeviceInfoItems(): { key: string; value: unknown }[] | null {
      if (typeof this.message?.deviceMetadata === "object") {
        return Object.entries(this.message?.deviceMetadata ?? {}).map(([key, value]) => ({
          key,
          value: value as unknown
        }));
      }
      return null;
    },
    isArchived(): boolean {
      return this.message?.isArchived ?? false;
    }
  },
  watch: {
    message: {
      immediate: true,
      handler(message: Messages.UserFeedback | null, oldMessage: Messages.UserFeedback | null) {
        if (oldMessage) {
          void this.$store.direct.dispatch.watchAccountData(oldMessage.currentAccount ?? "");
          void this.$store.direct.dispatch.watchUser(oldMessage.sentBy);
        }
        if (message) {
          void this.$store.direct.dispatch.watchAccountData(message.currentAccount ?? "");
          void this.$store.direct.dispatch.watchUser(message.sentBy);
        }
      }
    }
  },
  beforeDestroy() {
    if (this.message) {
      void this.$store.direct.dispatch.stopWatchingAccountData(this.message.currentAccount ?? "");
      void this.$store.direct.dispatch.unwatchUser(this.message.sentBy);
    }
  },
  methods: {
    async toggleArchive(): Promise<void> {
      if (!this.messageId) {
        return;
      }
      this.error = null;
      this.isWorking = true;

      try {
        await this.$store.direct.dispatch.setMessageArchived({
          messageId: this.messageId,
          isArchived: !this.isArchived
        });
      } catch (error) {
        console.error(`Failed to archive message ${this.messageId}:`, error);
        switch ((error as FirebaseError).code) {
          case "permission-denied":
            this.error = "You don't have permission to archive this message.";
            break;
          default:
            this.error = `Archive failed: ${JSON.stringify(error)}`;
        }
      }
      this.isWorking = false;
    },
    async deleteMessage(): Promise<void> {
      if (!this.messageId) {
        return;
      }
      if (
        !confirm(`Are you sure you want to delete ${this.senderName ?? "this user"}'s message?`)
      ) {
        return;
      }
      this.error = null;
      this.isWorking = true;
      this.cachedMessage = this.message;

      try {
        await this.$store.direct.dispatch.deleteMessage(this.messageId);
        this.$router.go(-1);
      } catch (error) {
        this.isWorking = false;
        this.cachedMessage = null;
        switch ((error as FirebaseError).code) {
          case "permission-denied":
            this.error = "You don't have permission to delete this message.";
            break;
          default:
            this.error = "Delete failed: " + JSON.stringify(error);
        }
        console.error(`Failed to delete message ${this.messageId}:`, error);
      }
    }
  }
});
